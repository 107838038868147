
<template>
  <div class="monitorViode">
    12312312321
    {{url}}
  </div>
</template>
<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import axios from "axios";
export default {
  props: ['url'],
  components: {},
  data() {
    return {

    };
  },
  created() {},
  mounted() {
    console.log(this.url);
  },
  beforeDestroy() {},
  // 计算属性
  computed: {},
  methods: {

  },
  watch: {},
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  background-color: #10121f;
  padding: 1vh;
}
body .monitorViode {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  position: relative;
}
</style>
